import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration
import { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import './App.css'; // Import your CSS file for the modal styles
import { openPageViewModal } from './PageViewModalUtils'; // Import the function to open the page view modal
import { Asset } from './types'; // Import the Asset type

interface Talent {
  token_address: string;
  token_id: string;
  name: string;
  owner: string;
  date_update: string;
  image_url: string;
  card_id: string;
  token_new_url?: string;  // Optional since it might be null
  card_experience?: number;
  card_force?: string;
  ETH_value?: string;
  EUR_value?: string;
  USD_value?: string;
  lineup?: number;
  total_lineup?: number;
  dollar_max?: number;
  euro_max?: number;
  eth_max?: number;
  buyer_max?: string;
  offers_count?: number;
  desired_eth?: number;
  desired_dollar?: number;
  desired_euro?: number;
  current_order?: number;
  pending?: number;
  toTransfer?: number;
  USD_ratio?: string;
  EUR_ratio?: string;
  ETH_ratio?: string;
  profile_new_url?: string;
  similar_cards_total?: number;
  similar_cards_on_sale?: number;
  similar_cards_total_pending?: number;
  lineup_per_talent?: number;
  available_cards?: number;
  potential_buyers?: string;
  total_pending?: number;
  country?: string;
  state?: string;
  city?: string;
  main_nationality?: string;
  main_activity?: string;
  main_category?: string;
  league?: string;
  league_url?: string;
  club?: string;
  club_url?: string;
  national_team?: string;
  national_team_url?: string;
  tag1?: string;
  tag2?: string;
  tag3?: string;
  tag4?: string;
  tag5?: string;
  prize1?: string;
  prize2?: string;
  prize3?: string;
  prize4?: string;
  prize5?: string;
  talent_age?: string;
  talent_name_original?: string;
  talent_first_name?: string;
  talent_last_name?: string;
  talent_instagram?: string;
  talent_youTube?: string;
  talent_facebook?: string;
  talent_tiktok?: string;
  talent_X?: string;  // For Twitter (previously known as Twitter/X)
  talent_pinterest?: string;
  talent_linkedIn?: string;
  talent_twitch?: string;
  talent_website?: string;
  talent_force?: string;
  talent_description?: string;
  instagram_followers?: string;
  talent_birthdate?: string;
  talent_experience?: string;
  json_data: Record<string, any>;
  current_score?: string;
  current_game?: string;
  card_xp_bonus?: string;
  card_force_bonus?: string;
  similar_cards_bonus?: string;
  final_score?: string;
  total_score?: string;
}

interface TalentSearchProps {
  handleLinkClick: (page: string, section: string) => void;
}

const TalentSearch: React.FC<TalentSearchProps> = ({ handleLinkClick }) => {

  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<Talent[]>([]);
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [allTalents, setAllTalents] = useState<Talent[]>([]); // Store all talents for random selection

  const [indexLanguage, setIndexLanguage] = useState('');

  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`)
    ? `https://${stagingKey}.peaxel.me`
    : 'https://peaxel.me';

  useEffect(() => {
    const storedLanguage = localStorage.getItem('pxlLanguage') || 'en'; // Default to 'en' if not found
    setIndexLanguage(storedLanguage);
  }, []);

  useEffect(() => {
    if (showModal) {
      const apiEndpoint = `${linkUrl}/wp-json/custom/v1/custom-search?searchterm=discover`;
      fetch(apiEndpoint)
        .then((response) => response.json())
        .then((data) => {
          setAllTalents(data); // Store all talents for random selection
          setSearchResults(getRandomTalents(data, 8)); // Show random talents initially when modal opens
        })
        .catch((error) => console.error('Error fetching talents:', error));
    }
  }, [showModal]);

  useEffect(() => {
    if (searchTerm.length > 2) {
      const apiEndpoint = `${linkUrl}/wp-json/custom/v1/custom-search?searchterm=${searchTerm}`;
      fetch(apiEndpoint)
        .then((response) => response.json())
        .then((data) => setSearchResults(data))
        .catch((error) => console.error('Error fetching search results:', error));
    } else if (allTalents.length > 0) {
      // If fewer than 2 characters, show random talents
      setSearchResults(getRandomTalents(allTalents, 8));
    }
  }, [searchTerm, allTalents]);

  // Function to randomly select up to 8 unique talents from the list
  const getRandomTalents = (talents: Talent[], count: number): Talent[] => {
    const shuffled = [...talents].sort(() => 0.5 - Math.random()); // Shuffle the array
    return shuffled.slice(0, Math.min(talents.length, count)); // Return up to 8 talents or fewer if less available
  };
  

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  const createAssetFromTalent = (talent: Talent): Asset => {
    const asset: Asset = {
      collection: {
        icon_url: '', // Using the profile picture as a fallback icon
        name: 'PEAXEL_INTRO_BIS', // Placeholder for collection name
      },
      created_at: new Date().toISOString(),
      description: talent.talent_description || '', // Description based on job and country
      id: talent.card_id,
      image_url: talent.image_url, // Using the profile picture as the main image
      metadata: {
        country: talent.country,
        description: talent.talent_description || '',
        name: talent.name,
        image_url: talent.token_new_url || '', // Reusing profile picture in metadata
      },
      name: talent.name,
      status: 'active', // Assuming a default active status
      token_address: talent.token_address, // Placeholder token address
      token_id: talent.token_id, // Placeholder token ID
      updated_at: new Date().toISOString(),
      uri: null,
      user: '', // Placeholder user
      talentCard: {
        token_address: talent.token_address,
        token_id: talent.token_id,
        token_new_url: talent.token_new_url || '', //
        card_id: talent.card_id,
        name: talent.name,
        owner: talent.owner,
        date_update: new Date().toISOString(),
        image_url: talent.token_new_url || '',
        card_experience: talent.card_experience || 0,
        card_force: talent.card_force || '',
        ETH_value: talent.ETH_value || '',
        EUR_value: talent.EUR_value || '',
        USD_value: talent.USD_value || '',
        lineup: 1,
        ETH_ratio: '',
        EUR_ratio: '',
        USD_ratio: '',
        buyer_max: '',
        current_order: '',
        desired_dollar: '',
        desired_eth: '',
        desired_euro: '',
        dollar_max: '',
        eth_max: '',
        euro_max: '',
        offers_count: '',
        pending: '',
        toTransfer: '',
        total_lineup: '',
        profile_new_url: talent.profile_new_url || '',
        similar_cards_total: 0,
        similar_cards_on_sale: 0,
        similar_cards_total_pending: 0,
        lineup_per_talent: 0,
        available_cards: 0,
        potential_buyers: '',
        total_pending: 0,
        country: talent.main_nationality || '',
        state: talent.state || '',
        city: talent.city || '',
        main_nationality: talent.main_nationality || '',
        main_activity: talent.main_activity || '',
        main_category: talent.main_category || '',
        league: talent.league || '',
        league_url: talent.league_url || '',
        club: talent.club || '',
        club_url: talent.club_url || '',
        national_team: talent.national_team || '',
        national_team_url: talent.national_team_url || '',
        tag1: talent.tag1 || '',
        tag2: talent.tag2 || '',
        tag3: talent.tag3 || '',
        tag4: talent.tag4 || '',
        tag5: talent.tag5 || '',
        prize1: talent.prize1 || '',
        prize2: talent.prize2 || '',
        prize3: talent.prize3 || '',
        prize4: talent.prize4 || '',
        prize5: talent.prize5 || '',
        talent_age: talent.talent_age || '',
        talent_name_original: talent.talent_name_original || '',
        talent_first_name: talent.talent_first_name || '',
        talent_last_name: talent.talent_last_name || '',
        talent_instagram: talent.talent_instagram || '',
        talent_youTube: talent.talent_youTube || '',
        talent_facebook: talent.talent_facebook || '',
        talent_tiktok: talent.talent_tiktok || '',
        talent_X: talent.talent_X || '',
        talent_pinterest: talent.talent_pinterest || '',
        talent_linkedIn: talent.talent_linkedIn || '',
        talent_twitch: talent.talent_twitch || '',
        talent_website: talent.talent_website || '',
        talent_force: talent.talent_force || '',
        talent_description: talent.talent_description || '',
        instagram_followers: talent.instagram_followers || '',
        talent_birthdate: talent.talent_birthdate || '',
        talent_experience: talent.talent_experience || '',
        json_data: talent.json_data || {},
        current_score: talent.current_score || '',
        current_game: talent.current_game || '',
        card_xp_bonus: talent.card_xp_bonus || '',
        card_force_bonus: talent.card_force_bonus || '',
        similar_cards_bonus: talent.similar_cards_bonus || '',
        final_score: talent.final_score || '',
        total_score: talent.total_score || ''
      },
    };
  
    // Only after defining it fully, return the asset
    console.log('assetrebuilt', asset);
    return asset;
  };

  const handleProfileClick = useCallback((talent: Talent) => {
    const asset = createAssetFromTalent(talent);
    openPageViewModal(asset, '');
  }, []);

  return (
    <div>
      <span onClick={() => setShowModal(true)} className="button-search">
        <i className="fa-solid fa-magnifying-glass"></i>
      </span>

      {showModal && (
        <div className='aroundModal'>
          <div className="modal-container" ref={modalRef}>
            <button className="close-button-modal" onClick={() => setShowModal(false)}>
              <i className="fa-regular fa-circle-xmark"></i>
            </button>

            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={t('searchForTalentsBox')}
            />
            {searchResults.slice(0, 8).map((result) => (
              <div
                key={result.name}
                className='line-search'
                onClick={() => handleProfileClick(result)} // Use the modal opening function here
              >
                <span>
                  <img src={result.profile_new_url} alt={result.name} className='asset-img-search' />
                </span>
                <span className='details-search'>
                  <span>
                    <strong>{result.name}</strong>
                  </span>
                  <span className='job-search'>
                    <div>{result.main_activity}&nbsp;•&nbsp;{result.main_nationality}</div>
                  </span>
                </span>
              </div>
            ))}
            
            <span className="advanced-search-link" onClick={() => handleLinkClick('market', 'allTalents')}>
              {t('advancedSearch')}
            </span>
          
          </div>
        </div>
      )}
    </div>
  );
};

export default TalentSearch;
